<template>
  <img
    class="p-m-5"
    src="../assets/logo-full.png"
    alt="conference logos"
    style="max-width: 250px;  margin-left: auto!important;  margin-right: auto!important;"
  />

  <div style="max-width: 1400px; margin: auto;">
    <div class="about card p-m-3 p-p-3 p-shadow-2 brw">
      <h1>About myCFP</h1>
      <p>
        myCFP was designed to make it easier for instructors submitting their
        presentations for upcoming conferences. By splitting up the process of
        submitting basic speaker information and individual presentation details
        the instructor is no longer required to submit their basic content with
        every new submission. Additionally, instructors can track the approval
        progress of their presentation (pending, accepted, declined).
      </p>
      <hr />

      <h2>Getting Started</h2>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-4">
          <img
            src="../assets/part-one.jpg"
            style="width: 100%;  padding:20px;"
          />
        </div>
        <div class="p-field p-col-12 p-md-8">
          <div>
            <h3>Step One</h3>
            <p>
              The first step is to register with myCFP. The only information
              needed is your email address and password. Passwords must be 8
              characters and include letters and numbers.
            </p>
            <h3>Step Two</h3>
            <p>
              Once you register you will automatically be sent to the next step
              to complete your basic profile. If you are not ready to start this
              section you can return to it at a later date by logging in with
              the same email and password you created at registration.
            </p>
            <p>
              You will need to have the following items ready to complete step
              #2:
            </p>
            <ul>
              <li>Upload Headshot (jpg, png)</li>
              <li>Upload Resume/CV or enter the information manually</li>
            </ul>
          </div>
        </div>
      </div>

      <hr />

      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-8">
          <h2>Submitting A Presentation</h2>

          <h3>Step One</h3>
          <p>From your dashboard click the "Submit Presentation" button.</p>
          <p>
            You will need to complete all fields for the presentation to be
            submitted.
          </p>
          <h3>Step Two</h3>
          <p>
            Once a presentation is submitted you can track the progress from
            your dashboard. Initially, it will be marked as "Pending". Once a
            decision has been made it will be changed to either "Accepted" or
            "Declined".
          </p>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <img
            src="../assets/part-two.jpg"
            style="width: 100%;  padding:20px;"
          />
        </div>
      </div>

      <hr />

      <h2>Editing Details</h2>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-4">
          <img
            src="../assets/part-three.jpg"
            style="width: 100%;  padding:20px;"
          />
        </div>
        <div class="p-field p-col-12 p-md-8">
          <h3>Step One</h3>
          <p>
            From the dashboard click the menu icon from the "Actions" column.
            Then click edit. This will display a dialog box where all necessary
            changes can be made.
          </p>
          <h3>Step Two</h3>
          <p>Click the "Update" button to return to your dashboard.</p>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>
